define('views/about-first',['jquery', 'backbone', 'underscore'], function($, Backbone, _) {

  var AboutFirst = Backbone.View.extend({
    el: document.getElementById('AboutFirst'),
    initialize: function() {

      var self = this;
      $.ajax({
        url: "http://wms.awstudio.fr/wp-json/posts/198"
      }).done(function(response) {

        self.$el.find('h1').html('<img src="'+response.featured_image.source+'" alt="'+response.featured_image.title+'">');
      });
    },
    render: function() {
      //temp = $('#AboutView') || temp;
      //  console.log("tata");
      //if(temp.length) {
      //
      //  this.template = _.template(temp.html());
      //
      //  this.$el.html(this.template());
      //}
      //
      //return this;

    }

  });

  return new AboutFirst();
});

