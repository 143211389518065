define('views/reference',['jquery', 'backbone', 'underscore', 'collections/references'], function($, Backbone, _, ReferencesCollection) {

  var temp;

  var ReferenceView = Backbone.View.extend({
    el: document.getElementById('ReferenceView'),
    events: {
      "click a.ref-details__header__back": "hide",
      "click a.ref-details__footer__back": "hide",
      "click a.ref-details__footer__top": "top",
      "click a.ref-details__header__next": "nextProject"
    },
    initialize: function() {


      this.$el.on('touchmove', function(e) { e.stopPropagation(); e.stopImmediatePropagation(); });

      var self = this;

      $(window).on('popstate', function(e) {

        var uri = 'http://wms.awstudio.fr'+window.location.pathname;
        var model = ReferencesCollection.findWhere({ link: uri });

        if(!!model) {

          self.setReferenceData(model).render().show();
        }
      });

    },
    render: function() {

      temp = $('#ReferenceTemplate') || temp;

      if(temp.length) {

        this.template = _.template(temp.html());

        window.history.pushState({ cid: this.reference.cid }, this.reference.get('title'), this.reference.get('link').replace('http://wms.awstudio.fr', ''));

        this.$el.html(this.template({ reference: this.reference }));
      }

      return this;

    },
    setReferenceData: function(model) {

      this.reference = model;

      return this;
    },
    show: function() {

      this.$el.addClass('state--ready state--open').scrollTop(1);

      window.savePos = {
        top: this.$el.parent().scrollTop(),
        left: this.$el.parent().scrollLeft()
      };

      $('#HomeView').addClass('state--hidden');
    },
    hide: function(e) {

      e.preventDefault();

      window.history.pushState({}, 'WeMakeSomething', '/');

      this.$el.removeClass('state--ready state--open');

      $('#HomeView').removeClass('state--hidden');

      setTimeout(function() {

        $('#HomeView').parent().scrollTop(window.savePos.top).scrollLeft(window.savePos.left);
      }, 0);
    },
    top: function() {

      //this.$el.scrollTop(1);

      this.$el.animate({
        'scrollTop': 1
      }, 300);
    },
    nextProject: function(e) {

      e.preventDefault();

      this.reference = this.reference.nextElement();

      this.render();
    }
  });

  return new ReferenceView();
});

