define('views/about',['jquery', 'backbone', 'underscore','instafeed'], function($, Backbone, _) {

  var temp;
  var feed;
  var imgs = [];

  var AboutView = Backbone.View.extend({
    el: document.getElementById('AboutView'),
    initialize: function() {

      var self = this;
        if(document.getElementById('AboutView')){
            feed = new Instafeed({
                get: 'user',
                userId: 628418274,
                accessToken: '628418274.467ede5.120eafe9c2854af684d22476201391e4',
                limit: 30,
                resolution: 'low_resolution',
                template: '<img src="{{image}}"/>',
                success: function (data) {
                    // read the feed data and create owr own data struture.
                    var images = data.data;
                    var result;
                    for (i = 0; i < images.length; i++) {
                        var image = images[i];
                        result = this._makeTemplate(this.options.template, {
                            model: image,
                            id: image.id,
                            link: image.link,
                            image: image.images[this.options.resolution].url
                        });
                        imgs.push(result);
                    }
                    //split the feed into divs
                    /*$("#about-instagram-1").html(imgs.slice(0, 6));
                    $("#about-instagram-2").html(imgs.slice(6, 12));
                    $("#about-instagram-3").html(imgs.slice(12, 18));
                    $("#about-instagram-4").html(imgs.slice(18, 24));
                    $("#about-instagram-4").html(imgs.slice(24, 30));*/

                    if(self.$el.children().length)
                      self.$el.children().html(imgs.slice(0, 30));
                    else
                      self.$el.html(imgs.slice(0, 30));
                }
            });
            //console.log(feed);
            feed.run();
            //this.render();
        }

    },
    render: function() {
      //temp = $('#AboutView') || temp;
      //  console.log("tata");
      //if(temp.length) {
      //
      //  this.template = _.template(temp.html());
      //
      //  this.$el.html(this.template());
      //}
      //
      //return this;

    }

  });

  return new AboutView();
});

