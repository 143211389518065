define('collections/references',['backbone', 'jquery', 'models/reference'],
function(Backbone, $, Reference) {

  var date;

  var references = Backbone.Collection.extend({

    model: Reference,
    comparator: function(model) {

      date = new Date(model.get('date'));

      return 0 - date.getTime();
    }

  });

  return new references();

});

