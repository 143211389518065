define('bootstrapper',[
  'jquery',
  'views/home',
  'collections/references',
  'views/about',
  'views/about-first',
  'jquery-ui/draggable',
  'jquery.ui.touch-punch',
  'requireLib',
  'fullpagejs'
], function($, HomeView, ReferencesCollection) {

  $('html').addClass('js').removeClass('no-js');

  var closeMenuLink, openMenuLink, menuBody, mobileMenuLink;


  $(document).ready(function() {

    // Prevent bouncing scroll for ipad
    if(window.innerWidth > 700) {
      document.ontouchmove = function(event){
        event.preventDefault();
      };
    }


    menuBody = $('.menu__body');
    openMenuLink = $('.menu__top-link--open').on('click', function(e) {

      e.preventDefault();

      menuBody.css('z-index', 10).addClass('state--open');
      closeMenuLink.css('display', 'inline-block');
      openMenuLink.hide();
    });
    closeMenuLink = $('.menu__top-link--close').on('click', function(e) {

      e.preventDefault();

      menuBody.removeClass('state--open');
      closeMenuLink.hide();
      openMenuLink.css('display', 'inline-block');

      setTimeout(function() {

        menuBody.css('z-index', 1);
      }, 500);
    });

    mobileMenuLink = $('.menu__mobile--toggle').on('click', function(e) {

      e.preventDefault();

      if(!menuBody.hasClass('state--open')) {

        menuBody.css('z-index', 10).addClass('state--open');
      }
      else {

        menuBody.removeClass('state--open');
        setTimeout(function() {

          menuBody.css('z-index', 1);
        }, 500);
      }


    });


    var req1 = ReferencesCollection.fetch({
      url: 'http://wms.awstudio.fr/wp-json/posts?type=image&_jsonp=?&filter[posts_per_page]=-1',
      dataType: 'jsonp',
      remove: false
    });
    var req2 = ReferencesCollection.fetch({
      url: 'http://wms.awstudio.fr/wp-json/posts?type=content&_jsonp=?&filter[posts_per_page]=-1',
      dataType: 'jsonp',
      remove: false
    });
    var req3 = ReferencesCollection.fetch({
      url: 'http://wms.awstudio.fr/wp-json/posts?type=project&_jsonp=?&filter[posts_per_page]=-1',
      dataType: 'jsonp',
      remove: false
    });
    var req4 = ReferencesCollection.fetch({
      url: 'http://wms.awstudio.fr/wp-json/posts?_jsonp=?&filter[posts_per_page]=-1',
      dataType: 'jsonp',
      remove: false
    });

    $.when(req1, req2, req3, req4).then(function() {

      ReferencesCollection.trigger('finished');
    });

    // to move to the view
    initHomeDrag();

    $('.contact-city a').click(function() {
      $('.contact-city a').removeClass('active');
      $(this).addClass('active');
      var href = $(this).attr('href');
      $('.contact-infos ul li').removeClass('active');
      $('li[data-infos="'+href+'"]').addClass('active');
    });


    var $fullPage = $('#about-fullpage');

    if($fullPage.length) {

      $fullPage.fullpage({
        anchors: ['about-page-0', 'about-page-1', 'about-page-2', 'about-page-3'],
        menu: '.about-scroll',
        sectionSelector: '.about',
        touchSensitivity: 99999,

        afterLoad: function(anchorLink, index){
          $('.about-scroll ul li').each(function() {
            $(this).removeClass('active');
          });
          $('.about-scroll ul li:nth-child('+ index +')').addClass('active');
        }
      });

      var touchY, touchY2;
      // touch
      $(document).on({
        touchstart: function(e) {
          touchY = e.originalEvent.targetTouches[0].pageY;
        },
        touchmove: function(e) {
          touchY2 = e.originalEvent.targetTouches[0].pageY;
        },
        touchend: function(e) {

          if(touchY2 > touchY + 15) {
            $.fn.fullpage.moveSectionUp();
          }
          else if(touchY2 < touchY - 15) {
            $.fn.fullpage.moveSectionDown();
          }
        }
      });
    }




  });


  var posX, posY, prevX, prevY;

  function initHomeDrag() {

    var $draggable = $('.js-grabbable');
    var $container = $draggable.parent();

    if($draggable.length) {

      var mX = 0 - $draggable.width() - $(window).width();
      var mY = 0 - $draggable.height() - $(window).height();
      var x = ($(window).width() / 2) - ($draggable.width() / 2);
      var y = ($(window).height() / 2) - ($draggable.height() / 2);

      $draggable.css({
        left: x,
        top: y
      });

      if(window.innerWidth > 700 && window.innerWidth <= 1100) {

        $container.scrollTop($draggable.height() / 2);
        $container.scrollLeft($draggable.width() / 2);

        $container.on('touchmove', function(e) { e.stopPropagation(); e.stopImmediatePropagation(); });
      }

      $container.on('mousemove', function(e) {
        posX = e.pageX;
        posY = e.pageY;
      });
      $container.on('touchstart', function(e) {

        $container.off('mousemove');

        /*if(window.innerWidth > 700) {

          $draggable.draggable({
            drag: function(event, ui) {

              var top = ui.position.top;
              var left = ui.position.left;

              if(top < mY) {
                top = mY;
              }
              else if(top > 0) {
                top = 0;
              }

              if(left < mX) {
                left = mX;
              }
              else if(left > 0) {
                left = 0;
              }

              ui.position.top = top;
              ui.position.left = left;
            }
          });


        }*/

        window.isTouchDevice = true;

      });
      /*$container.on('touchmove', function(e) {
        posX = window.innerWidth - e.originalEvent.targetTouches[0].pageX;
        posY = window.innerHeight - e.originalEvent.targetTouches[0].pageY;
      });*/

      setInterval(function() {

        if(!!window.isTouchDevice) return;

        var perX = posX * 100 / ($container.width() / 2);
        var perY = posY * 100 / ($container.height() / 2);

        var x = Math.round(-perX * (($draggable.width() - $container.width()) / 2) / 100);
        var y = Math.round(-perY * (($draggable.height() - $container.height()) / 2) / 100);

        x = (x > $draggable.position().left) ? x - (Math.abs(x - $draggable.position().left)/1.03) : x + (Math.abs(x - $draggable.position().left)/1.03);
        y = (y > $draggable.position().top) ? y - (Math.abs(y - $draggable.position().top)/1.03) : y + (Math.abs(y - $draggable.position().top)/1.03);

        $draggable.css({
          left: x+'px',
          top: y+'px'
        });

      }, 1000/120);
    }
  }

  function openDetails($container, $ref) {

    $container.addClass('state--ready state--open');
  }


  window.editor = function() {

    var $items = $('.reference-block');

    if($items.length) {

      $items.draggable({
        grid: [ 15, 15 ],
        drag: function(event, ui) {

          $('#EDITOR').html('Coordonnées : <br><br>X : '+ui.position.left+' | Y : '+ui.position.top);
        }
      });

      $('body').append('<div id="EDITOR">Coordonnées : <br><br>X : 0 | Y : 0</div>');

      $('#EDITOR').css({
        position: 'fixed',
        top: 0,
        right: 0,
        padding: '30px',
        border: '5px solid #000000',
        background: '#ffffff',
        fontSize: '16px'
      });
    }
  };

});

