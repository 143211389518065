define('views/home',['jquery', 'backbone', 'underscore', 'collections/references', 'views/reference'],
function($, Backbone, _, ReferencesCollection, ReferenceView) {

  var model, temp;


  function loadReferenceImage($img) {

    var defer = $.Deferred();

    $img.on('load', function() {

      defer.resolve();
    });

    setTimeout(function() {

      defer.resolve();
    }, 1000 * 20);

    return defer.promise();
  }

  var HomeView = Backbone.View.extend({
    el: document.getElementById('HomeView'),
    initialize: function() {

      ReferencesCollection.on('finished', this.render, this);

      $(window).on('popstate', function(e) {

        var uri = 'http://wms.awstudio.fr'+window.location.pathname;
        var model = ReferencesCollection.findWhere({ link: uri });

        if(!model) {

          ReferenceView.$el.removeClass('state--ready state--open');

          $('#HomeView').removeClass('state--hidden');

          setTimeout(function() {

            $('#HomeView').parent().scrollTop(window.savePos.top).scrollLeft(window.savePos.left);
          }, 0);
        }
      });
    },
    events: {
      'click a.reference-block--project': 'onReferenceClick',
      'click a.news-link': 'onNewsClick'
    },
    render: function() {

      temp = $('#HomeTemplate') || temp;

      if(temp.length) {

        this.template = _.template(temp.html());

        this.$el.html(this.template({ references: ReferencesCollection }));

        var loadings = [];
        this.$el.find('.reference-block figure img').each(function() {

          loadings.push(loadReferenceImage($(this)));
        });

        var self = this;
        $.when.apply($, loadings).then(function() {

          self.$el.parent().children('.references-loader').fadeOut();
        });

        this.resizeContainer();

        // checks URL and load ref detail if needed
        var uri = 'http://wms.awstudio.fr'+window.location.pathname;
        var model = ReferencesCollection.findWhere({ link: uri });

        if(!!model) {

          ReferenceView.setReferenceData(model).render().show();
        }
      }

    },
    onReferenceClick: function(e) {

      e.preventDefault();

      model = ReferencesCollection.get($(e.target).closest('a').attr('data-cid'));

      ReferenceView.setReferenceData(model).render().show();
    },
    onNewsClick: function(e) {

      e.preventDefault();

      model = ReferencesCollection.get($(e.target).closest('a').attr('data-cid'));

      ReferenceView.setReferenceData(model).render().show();
    },
    resizeContainer: function() {

      var self = this;
      var left = 9999;
      var right = 0;

      this.$el.children('.reference-block').each(function() {

        var $this = $(this);

        if($this.position().left < left) left = $this.position().left;

        if($this.position().left + $this.width() > right) right = $this.position().left + $this.width();
      });

      console.log(left);

      this.$el.width(right - left);

      this.$el.children('.reference-block').css('margin-left', '-'+left+'px');

      var x = ($(window).width() / 2) - (this.$el.width() / 2);
      var y = ($(window).height() / 2) - (this.$el.height() / 2);

      this.$el.css({
        left: x,
        top: y
      });

      if(window.innerWidth > 700 && window.innerWidth <= 1100) {

        this.$el.parent().scrollTop((this.$el.height() - $(window).height()) / 2);
        this.$el.parent().scrollLeft((this.$el.width() - $(window).width()) / 2);
      }
    }
  });

  return new HomeView();
});

