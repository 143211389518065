define('models/reference',['backbone'], function(Backbone) {

  var Reference = Backbone.Model.extend({

    nextElement: function() {
      var index = this.collection.indexOf(this);
      if ((index + 1) === this.collection.length) {

        index = -1;
      }
      return getProject(this.collection, ++index);
    },

    formatDate: function() {

      var date = new Date(this.get('date'));

      var month = date.getMonth()+1;

      return date.getDate()+'.'+month+'.'+date.getFullYear();
    }
  });

  var proj;

  function getProject(collection, index) {

    proj = collection.at(index);

    if(proj.attributes.type === 'project') {

      return proj;
    }
    else {

      if ((index + 1) === collection.length) {

        index = -1;
      }

      return getProject(collection, ++index);
    }
  }

  return Reference;

});

